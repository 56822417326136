import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import StaticTexts from '../../../../../assets/static-texts/static-texts';

@Component({
    selector: 'app-user-agreement',
    templateUrl: './user-agreement.component.html',
    styleUrls: ['./user-agreement.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class UserAgreementComponent implements OnInit {
    texts = StaticTexts.signupTexts;
    displayText = this.texts.TR;
    listItems: Array<any> = [];

    setDisplayText = () => {
        if (this.translate.currentLang === 'tr') {
          this.displayText = this.texts.TR;
        }else{
          this.displayText = this.texts.EN;
        }
        this.listItems = this.displayText.UserAgreementContent;
      }

      constructor(private translate: TranslateService,
        private dialogRef: MatDialogRef<UserAgreementComponent>) {}

      ngOnInit(){
        this.setDisplayText();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.setDisplayText();
        });
      }

      agreeTerms = () => {
        this.dialogRef.close({result: 'agree'});
      }
  }

