<mat-dialog-content style="height: calc(100% - 48px);max-height: unset">
<div class="wrapper">
        <div class="terms-header">
            <h1 class="terms-title">{{'TERMS_AND_CONDITIONS' | translate}}</h1>
            <p class="terms-info">{{'YOU_CONSIDERED_ACCEPTED_TERMS' | translate}}</p>
        </div>
        <ol>
            <li class="terms-text" *ngFor="let item of listItems">{{item.text}}</li>
        </ol>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button  (click)="agreeTerms()" class="terms-button">{{'CONFIRM' | translate}}</button>
  </mat-dialog-actions>